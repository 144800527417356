define("discourse/plugins/discourse-shared-edits/lib/shared-edits", ["exports", "discourse/lib/ajax", "@ember/object", "discourse/lib/ajax-error", "discourse/lib/load-script", "@ember/runloop"], function (_exports, _ajax, _object, _ajaxError, _loadScript, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.performSharedEdit = performSharedEdit;
  _exports.setupSharedEdit = setupSharedEdit;
  _exports.teardownSharedEdit = teardownSharedEdit;
  const THROTTLE_SAVE = 500;
  function setupSharedEdit(composer) {
    const manager = SharedEditManager.create();
    composer.set("sharedEditManager", manager);
    (0, _ajax.ajax)(`/shared_edits/p/${composer.post.id}`).then(data => {
      manager.set("version", data.version);
      manager.set("raw", data.raw);
      manager.set("composer", composer);
      composer.set("reply", data.raw);
      manager.subscribe();
    }).catch(_ajaxError.popupAjaxError);
  }
  let loadedTextUnicode = false;
  function teardownSharedEdit(composer) {
    const post = composer.post;
    const manager = composer.sharedEditManager;
    if (manager) {
      manager.commit();
    }
    composer.messageBus.unsubscribe(`/shared_edits/${post.id}`);
    composer.set("sharedEditManager.composer", null);
    composer.set("sharedEditManager", null);
  }
  function performSharedEdit(composer) {
    if (composer.sharedEditManager) {
      composer.sharedEditManager.performSharedEdit();
    }
  }
  function diff(before, after) {
    const diffLib = window.otLib.default.OtDiff.diff;
    const changes = diffLib(before, after);
    return compress(changes);
  }
  function compress(change) {
    let compressed = [];
    if (change.action !== "noop") {
      if (change.start > 0) {
        compressed.push(change.start);
      }
      switch (change.action) {
        case "replace":
          compressed.push({
            d: change.remove
          });
          compressed.push(change.payload);
          break;
        case "insert":
          compressed.push(change.payload);
          break;
        case "delete":
          compressed.push({
            d: change.remove
          });
          break;
      }
    }
    return compressed;
  }
  const SharedEditManager = _object.default.extend({
    raw: null,
    version: null,
    submittedChanges: null,
    pendingChanges: null,
    ajaxInProgress: false,
    commit() {
      (0, _ajax.ajax)(`/shared_edits/p/${this.composer.post.id}/commit`, {
        method: "PUT"
      }).catch(_ajaxError.popupAjaxError);
    },
    performSharedEdit() {
      if (loadedTextUnicode) {
        this.sendDiffThrottled();
      } else {
        (0, _loadScript.default)("/plugins/discourse-shared-edits/javascripts/text-unicode-dist.js").then(() => {
          loadedTextUnicode = true;
          this.sendDiffThrottled();
        });
      }
    },
    sendDiffThrottled() {
      (0, _runloop.throttle)(this, "sendDiff", THROTTLE_SAVE, false);
    },
    sendDiff() {
      const composer = this.composer;
      if (!composer) {
        return;
      }
      if (this.ajaxInProgress) {
        this.sendDiffThrottled();
        return;
      }
      const changes = diff(this.raw, composer.reply);
      const submittedRaw = composer.reply;
      if (changes.length > 0) {
        this.ajaxInProgress = true;
        (0, _ajax.ajax)(`/shared_edits/p/${composer.post.id}`, {
          method: "PUT",
          data: {
            revision: JSON.stringify(changes),
            version: this.version,
            client_id: composer.messageBus.clientId
          }
        }).then(result => {
          const inProgressChanges = diff(submittedRaw, composer.reply);
          this.applyRevisions(result.revisions, inProgressChanges);
        }).finally(() => {
          this.ajaxInProgress = false;
        });
      }
    },
    applyRevisions(revs, inProgressChanges) {
      let currentChanges = inProgressChanges || diff(this.raw, this.composer.reply);
      let newRaw = this.raw;
      let newVersion = this.version;
      const otUnicode = window.otLib.default.OtUnicode;
      let newChanges = [];
      revs.forEach(revision => {
        if (revision.version === newVersion + 1) {
          let parsedRevision = JSON.parse(revision.revision);
          newRaw = otUnicode.apply(newRaw, parsedRevision);
          newVersion = revision.version;
          if (revision.client_id !== this.composer.messageBus.clientId) {
            newChanges = otUnicode.compose(newChanges, parsedRevision);
            currentChanges = otUnicode.transform(currentChanges, parsedRevision, "left");
          }
        }
      });
      this.set("raw", newRaw);
      this.set("version", newVersion);
      if (currentChanges.length > 0) {
        newRaw = otUnicode.apply(newRaw, currentChanges);
      }
      if (newRaw !== this.composer.reply) {
        const input = document.querySelector("#reply-control textarea.d-editor-input");
        if (input.selectionStart || input.selectionStart === 0) {
          const selLength = input.selectionEnd - input.selectionStart;
          let position = otUnicode.transformPosition(input.selectionStart, newChanges);

          // still need to compensate for scrollHeight changes
          // but at least this is mostly stable
          const scrollTop = input.scrollTop;
          input.value = newRaw;
          input.selectionStart = position;
          input.selectionEnd = position + selLength;
          window.requestAnimationFrame(() => {
            input.scrollTop = scrollTop;
          });
        }
        this.composer.set("reply", newRaw);
      }
    },
    subscribe() {
      const composer = this.composer;
      const post = composer.post;
      composer.messageBus.subscribe(`/shared_edits/${post.id}`, message => {
        if (message.client_id !== composer.messageBus.clientId && !this.ajaxInProgress) {
          this.applyRevisions([message]);
        }
      });
    }
  });
});